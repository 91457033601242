.ExcelTable {
  border: 1px solid #e0e0e0;
  border-width: 1px 0px 0px 1px;
  font-size: 11pt;
  font-family: Calibri;
  font-weight: 100;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 10px;
}

.ExcelTable TH {
  background-color: #f0f0f0;
  font-weight: bold;
  font-size: 14px;
  border: 1px solid #ababab;
  border-width: 0px 1px 1px 0px;
  height: 17px;
  line-height: 17px;
}

.ExcelTable TD {
  border: 0px;
  background-color: white;
  padding: 0px 4px 0px 2px;
  border: 1px solid #e0e0e0;
  border-width: 0px 1px 1px 0px;
}

.ExcelTable TD B {
  border: 0px;
  background-color: white;
  font-weight: bold;
}

.ExcelTable TD.heading {
  background-color: #f0f0f0;
  text-align: center;
  border: 1px solid #ababab;
  border-width: 0px 1px 1px 0px;
  font-weight: bold;
}

.ExcelTable TH.heading {
  background-color: #f0f0f0;
}

TH {
  text-align: center;
}
